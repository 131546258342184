.lvs-footer {
  clear: both;
  display: flex;
  background-color: #f8f8f8;
  min-height: 100px;
  width: 100%;
  margin: auto 0;
  margin-top: 100px;
  &__container {
    margin-top: 45px;
    margin-left: 50px;
    p {
      font-size: 12px;
    }
  }
}
