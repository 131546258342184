.lvs-header {
  top: 0;
  z-index: 2;
  width: 100%;
  height: 75px;

  display: flex;
  position: sticky;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px #0000001a;

  &__drawer-menu-container {
    display: flex;
    margin-top: 12.5px;
  }
  &__logo-container {
    margin-left: 15px;
  }

  &__toolbar-container {
    margin-top: 12.5px;
    margin-right: 15px;
  }
}
