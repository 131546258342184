.lvs-drawer-menu {
  &__toggle {
    margin-top: 8px;
    margin-left: 8px;
  }
  &__view {
    &--display-logo {
      display: flex;
      justify-content: center;
    }
  }
}
