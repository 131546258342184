@import "../../../styles/abstracts/variables";

.lvs-hero-image {
  &__content {
    &--display {
      padding-top: 100px;
      margin-left: 50px;

      h1 {
        color: #ffffff;
        font-size: 52px;
        font-family: "Lato", sans-serif;
        line-height: 1;
        text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
          0 6px 6px rgba(0, 0, 0, 0.23);
      }
      h2 {
        color: #ffffff;
        font-size: 38px;
        font-family: "Lato", sans-serif;
        text-shadow: 0 10px 20px #00000030, 0 6px 6px rgba(0, 0, 0, 0.23);
      }
      .ant-btn-lg {
        height: 50px;
        box-shadow: 0px 3px 10px #0000001a;
      }
    }
  }
}
