.lvs-toolbar {
 
  font-weight: 400;
  border: none;
  .ant-menu-item .anticon,
  .ant-menu-submenu-title .anticon {
    margin-right: initial;
   
  }
}
