@import "../../../styles/abstracts/variables";

.default-section {
  $default-section: &;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding: 25px 0 25px 0;
  &__content {
    max-width: 800px;
    padding-top: 50px;
    margin-left: 25px;
    margin-right: 25px;
    padding-left: 50px;
    padding-right: 50px;
    h2 {
      font-size: 32px;
      line-height: 0.5;
    }
    p {
      font-size: 20px;
    }
    .ant-btn-lg:hover {
      box-shadow: 0px 3px 10px #0000001a;
      background-color: $secondary-color;
      border: solid 1px $secondary-color;
    }
  }

  &__image {
    max-width: 650px;
    width: 100%;
    height: 450px;
  }
}
