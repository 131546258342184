a {
  display: flex;
  h3 {
    font-size: 18px;
    margin-right: 10px;
    margin-top: 25px;
    font-family: "Lato", sans-serif;
    text-shadow: 0px 3px 10px #0000001a;
  }
}
