.paragraph-section {
  margin-top: 5px;
  p {
    margin-top: 0;
    margin-bottom: 1em;
    color: #ffffff;
    font-size: 28px;
    font-family: "Lato", sans-serif;
    text-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}
